import { Button, ButtonProps, CircularProgress } from '@material-ui/core'
import React, { FC } from 'react'

type FormButtonProps = ButtonProps & {
  pending: boolean
}

export const FormButton: FC<FormButtonProps> = ({
  pending,
  children,
  ...rest
}) => {
  return (
    <Button type="submit" disabled={pending} {...rest}>
      {pending ? <CircularProgress color="inherit" size="1.2em" /> : children}
    </Button>
  )
}
