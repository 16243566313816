import { useState } from 'react'

import { useToast } from '@/hooks/useToast'
import { FormPayload, formService } from '@/services/formService'

export function useFormService() {
  const [pending, setPending] = useState(false)
  const { show } = useToast()

  const handleFormSuccess = () => {
    show({
      message: 'Form successfully submitted!',
      severity: 'success',
    })
  }

  const handleFormFailure = () => {
    show({
      message: 'We were not able to process your submission. Please try again.',
      severity: 'error',
    })
  }

  const submit = async (
    payload: FormPayload,
    onSuccess?: () => void,
    onFailure?: () => void
  ) => {
    setPending(true)

    try {
      const response = await formService.submit(payload)

      if (response.ok) {
        onSuccess?.()
        handleFormSuccess()
      } else {
        onFailure?.()
        handleFormFailure()
      }
    } catch (error) {
      onFailure?.()
      handleFormFailure()
    } finally {
      setPending(false)
    }
  }

  return { pending, submit }
}
