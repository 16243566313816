export type FormPayload = {
  visitor_name: string
  visitor_email?: string
  message: string
  recipient_name?: string
  recipient_email?: string | null
  email_type: string
}

export const formService = {
  submit: async (payload: FormPayload) => {
    return fetch('https://functions.testing.ibc.berlin/v1/emails/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
  },
}
